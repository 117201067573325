<template>
    <v-layout column>
        <v-layout style="margin-top: 20px;" column>
            <transport/>
            <v-layout style="margin-top: 20px;">
                <countries-list title="По стране"/>
                <periods/>
            </v-layout>
        </v-layout>

        <main-count :count="totalCount" :loading="loading" label="Создано запросов"/>

        <column-chart :title="chartLabel"
                      :loading="loading"
                      v-if="totalCount"
                      :data="chartData"/>

        <chart-xls-label
                    title="Показаны самые популярные страны. Данные по всем странам и областям доступны в файле."
                    @loadXLS="loadXLS('cars')"
                    loadXLS
        />

    </v-layout>
</template>

<script>
    import CountriesList from "./inputs/CountriesList";
    import Transport from "./inputs/Transport";
    import Periods from "./inputs/Periods";
    import MainCount from "./counts/MainCount";
    import ColumnChart from "./charts/ColumnChart";
    import ChartXlsLabel from "./charts/ChartXlsLabel";
    import XlsMixin from "../../mixins/XlsMixin";

    export default {
        components: {
            ChartXlsLabel,
            ColumnChart,
            MainCount,
            Periods,
            Transport,
            CountriesList
        },
        mixins: [XlsMixin],
        name: "statistics-by-car",
        data: () => ({
            loading: false,
            totalCount: 0,
            chartData: [],
            period: 1
        }),
        computed: {
            chartLabel() {
                return this.$store.state.chartLabel
            },
            timePeriods() {
                return this.$store.state.timePeriods
            }
        },
        watch: {
            '$route'() {
                this.getData()
            }
        },
        methods: {
            getData() {
                this.loading = true;
                const query = this.$route.query;

                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        period: query.period || this.timePeriods.default,
                        date: query.date || new Date().toISOString().substring(0, 7),
                        idCountry: query.idCountry,
                        idCarBrand: query.idCarBrand,
                        idCarModel: query.idCarModel,
                        idCarType: query.idCarType || 1
                    }
                };
                console.log(params.params);
                this.$http.get("/statistics/cars", params)
                    .then(response => {
                        this.totalCount = response.data.data.totalCount;
                        this.chartData = response.data.data.items
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    }).finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            },
            loadXLS(type) {
                this.downloadFile(type)
            }
        },
        beforeMount() {
            this.getData()
        }
    }
</script>

<style scoped>

</style>