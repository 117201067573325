<template>
    <v-layout column>
        <label class="subtitle-2 font-weight-regular">ТС</label>
        <v-layout>
            <v-select filled
                      class="subtitle-1 black--text"
                      placeholder="Все страны"
                      disabled
                      single-line
                      color="secondary"
                      dense
                      :items="carTypesItems"
                      item-value="idCarType"
                      item-text="title"
                      item-color="secondary"
                      v-model="idCarType"
                      hide-details
                      style="max-width: 300px; margin-top: 10px;"/>

            <v-autocomplete filled
                            color="secondary"
                            class="subtitle-1 black--text"
                            placeholder="Все марки"
                            clearable
                            single-line
                            dense
                            hide-details
                            :items="searchedItems"
                            hide-no-data
                            v-model="selectedCar"
                            item-text="title"
                            item-value="idCarBrand"
                            item-color="secondary"
                            return-object
                            :search-input.sync="search"
                            @change="changeCar"
                            @click:clear="changeCar(null)"
                            clearable
                            style="max-width: 300px; margin-top: 10px; margin-left: 20px;"/>

            <v-select filled
                      class="subtitle-1 black--text"
                      placeholder="Все модели"
                      :disabled="!selectedCar"
                      single-line
                      color="secondary"
                      dense
                      no-data-text="Список пуст"
                      :items="carModels"
                      item-value="idCarModel"
                      item-text="title"
                      item-color="secondary"
                      v-model="idCarModel"
                      clearable
                      @change="changeCarModel"
                      hide-details
                      style="max-width: 300px; margin-top: 10px; margin-left: 20px;"/>
        </v-layout>
    </v-layout>
</template>

<script>
    export default {
        name: "transport",
        data: () => ({
            idCarType: 1,
            carModels: [],
            idCarModel: null,
            selectedCar: null,
            search: "",
            searchLimit: 1000,
            searchedItems: [],
        }),
        computed: {
            carTypesItems() {
                return this.$store.state.carTypesItems
            },
        },
        watch: {
            search(val) {
                let params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        page: 0,
                        limit: this.searchLimit,
                        searchString: val
                    }
                };

                this.$http.get("/cars/brands", params)
                    .then(response => {
                        this.searchedItems = response.data.data
                    }).catch(error => {
                    console.log(error);
                    switch (error.response.status) {
                        case 406:
                            // this.$router.history.push({name: 'login'})
                            break;
                        case 401:
                            this.$router.history.push({name: 'login'});
                            break;
                        default:
                            // console.log('error');
                            break;
                    }
                })
            },
            selectedCar(to) {
                if (to) this.getCarModels()
            },
            '$route'(to) {
                this.parseCar();
                this.parseCarType();
                if (this.selectedCar) this.parseCarModel();
            }
        },
        methods: {
            getCarModels() {
                const params = {
                    headers: {token: localStorage.getItem('token')},
                    params: {
                        idCarBrand: this.selectedCar.idCarBrand,
                        page: 0,
                        limit: 1000
                    }
                };
                console.log(params)
                this.$http.get("/cars/models", params)
                    .then(response => {
                        this.carModels = response.data.data;
                        this.parseCarModel();
                    })
                    .catch(error => {
                        console.log(error)
                        switch (error.response.status) {
                            case 406:
                                // this.$router.history.push({name: 'login'})
                                break;
                            case 401:
                                this.$router.history.push({name: 'login'});
                                break;
                            default:
                                console.log('error');
                                break;
                        }
                    })
            },
            parseCarType() {
                this.idCarType = this.$route.query.idCarType ? parseInt(this.$route.query.idCarType) : 1
            },
            parseCar() {
                if (this.$route.query.idCarBrand && this.$route.query.title) {
                    const car = {
                        idCarBrand: parseInt(this.$route.query.idCarBrand),
                        title: this.$route.query.title
                    }
                    this.searchedItems = [car];
                    this.selectedCar = car;
                    this.$store.dispatch("setChartLabel", `По моделям ${car.title}`)
                } else {
                    this.$store.dispatch("setChartLabel", "По марке")
                }
            },
            parseCarModel() {
                this.idCarModel = this.$route.query.idCarModel ? parseInt(this.$route.query.idCarModel) : null
                if (this.idCarModel && this.carModels.length) {
                    let label = `По поколениям ${this.selectedCar.title} `;
                    label += this.carModels.filter(v => v.idCarModel === this.idCarModel)[0]["title"]
                    this.$store.dispatch("setChartLabel", label)
                } else {
                    this.$store.dispatch("setChartLabel", `По моделям ${this.selectedCar.title}`)
                }
            },
            changeCar(value) {
                let query = {...this.$route.query};
                if (value) {
                    delete query.idCarModel;
                    this.$router.history.push({
                        name: this.$route.name, query: {...query, idCarBrand: value.idCarBrand, title: value.title}
                    })
                } else {
                    this.idCarModel = null;
                    delete query.idCarBrand;
                    delete query.title;
                    delete query.idCarModel;
                    this.$router.history.push({
                        name: this.$route.name, query: {...query}
                    });
                }
            },
            changeCarModel(value) {
                const query = this.$route.query || {};
                this.$router.history.push({
                    name: this.$route.name, query: {...query, idCarModel: value}
                });
            },
        },
        created() {
            this.parseCar();
            this.parseCarType();
            if (this.selectedCar) this.parseCarModel();
        }
    }
</script>

<style scoped>

</style>